import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import { initToast } from './components/Toast/Toast.jsx';

initToast(); /** toast initialize */

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
