import React, { useState, useEffect } from "react";
import api from "../../api";
import SingleProjectView from "../../components/ProjectLogout/SingleProjectView";
import ProjectView from "../../components/Project/ProjectView";
import { useParams } from "react-router-dom";
import "../../styles/SingleProject.css"
import backIcon from "../../assets/Profile/left-arrow.svg"

function SingleProject() {
  const [currentUser, setCurrentUser] = useState(null);
  const [projects, setProjects] = useState([]);

  const { projectId } = useParams();

  useEffect(() => {
    getCurrentUser();
    getProjects();
  }, []);

  // 현재 로그인한 사용자 정보 가져오기
  const getCurrentUser = () => {
    api
      .get("/api/current-user/") // 'current-user' 엔드포인트 호출
      .then((res) => {
        console.log(res.data);  // 사용자 정보 콘솔에 출력
        setCurrentUser(res.data); // 사용자 정보 상태에 저장
      })
      .catch((err) => {setCurrentUser(null)});
  };

  // 프로젝트 정보 가져오기
  const getProjects = () => {
    api
      .get( `/api/projects/${projectId}/`)
      .then((res) => res.data)
      .then((data) => {
        setProjects(data);
      })
      .catch((err) => alert(err));
  };

  /** go back handler */
  const handleBackButton = () => {
    window.history.back();
  };

  if (currentUser) {
    return (
        <div>
          {/** Backward button */}
          <div className="SingleProject-backward-btn-container">
            <button
            className="SingleProject-backbutton"
            onClick={() => handleBackButton()}
            >
            <img src={backIcon} />
            </button>
          </div>
          <ProjectView
            viewId={`proj-${projects["project_id"]}`}
            key={projects["project_id"]}
            projectId={projects["project_id"]}
            updateProjectList={getProjects}
          />
        </div>
      );
  }
  
  return (
    <div>
      {/** Backward button */}
      <div className="SingleProject-backward-btn-container">
        <button
        className="SingleProject-backbutton"
        onClick={() => handleBackButton()}
        >
        <img src={backIcon} />
        </button>
      </div>
      <SingleProjectView
        viewId={`proj-${projects["project_id"]}`}
        key={projects["project_id"]}
        projectId={projects["project_id"]}
        updateProjectList={getProjects}
      />
    </div>
  );
}

export default SingleProject;
